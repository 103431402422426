import ErrorBoundaryMessage from './ErrorBoundaryMessage';

import * as React from 'react';
import * as Sentry from '@sentry/react';

const ErrorBoundary: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  const { children } = props;

  return (
    <Sentry.ErrorBoundary
      fallback={<ErrorBoundaryMessage />}
      onError={(error) => {
        // Reload the page if the error is due to a missing module. This can
        // occur if the user navigates to a page with a component loaded via
        // a dynamic import after the site has been updated.
        if (
          error instanceof Error &&
          error.message.includes('error loading dynamically imported module')
        ) {
          location.reload();
        }
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
